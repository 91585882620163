.animation{
    animation: pulse 2s infinite;
}

.test{
    background-color: aqua;
}

@keyframes wobble {
    25% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(-30deg);
    }
    75% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.loading_box[wobble='1']{animation: glow 800ms ease-out infinite alternate; }
@keyframes glow {
    0% {
        border-color: #00a6a4;
        box-shadow: 0 0 5px rgba(0,255,0,.2), inset 0 0 5px rgba(0,255,0,.1), 0 1px 0 #393;
    }
    100% {
        border-color: #00a6a4;
        box-shadow: 0 0 40px #00a6a4, inset 0 0 30px #00a6a4, 0 1px 0 #6f6;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
